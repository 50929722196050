import { Inject, Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { PersistentStore } from './persistent-store.service';
import { MatDialog } from '@angular/material/dialog';
import { DataCacheService } from './data-cache.service';
import { ScriptService } from './script.service';
import { CredentialResponse } from 'google-one-tap';
import { ThemeManagerService } from './theme-manager.service';
import { CryptlexApiService } from './cryptlex-api.service';
import { initializeIntercom, shutdownIntercom } from '../_utils/intercom';
import {
  CtxPlanResponse,
  dateDifference,
  environment,
  getDashboardVersion,
  loginState,
  projectEnv,
  resetPosthog,
  waitForSomeTime,
} from 'utils';
import { HttpResponse } from '@angular/common/http';
import { IncorrectRoleErrorComponent } from '../_components/customer-auth-module/incorrect-role-error/incorrect-role-error.component';
import { AccountAliasesService } from './account-aliases.service';
import { isPlatformBrowser } from '@angular/common';
import { ConfigurationService } from './configuration.service';

type LoginBase = {
  email: string;
  password: string;
  twoFactorCode?: string;
  twoFactorRecoveryCode?: string;
};
type Signup = {
  email: string;
  password: string;
  firstname: string;
  lastname: string;
  company: string;
  recaptcha: string;
};
type LoginWithAlias = LoginBase & { accountAlias: string };
type LoginWithId = LoginBase & { accountId: string };
export type SubscriptionInfo = {
  status: string;
  paymentDueFrom?: number; // number of days from which payment is due
  subscriptionPastDueGracePeriod: number; // Number of grace days allowed for payment.
};
export type LoginRequestBody = LoginWithAlias | LoginWithId;

@Injectable({
  providedIn: 'root',
})
export class AuthnService {
  /**Available Lockout timeframe */
  private sessionExpirationTimeoutId: number | null = null;

  /** Account Alias for the current instance of the application. This is set in the constructor asynchronously */
  accountAlias: string;

  /** TODO, remove store for Email and Password in application */
  email = '';
  password = '';
  returnUrl = '/';

  loginState: loginState;

  constructor(
    private apiService: CryptlexApiService,
    private dataCacheService: DataCacheService,
    private dialog: MatDialog,
    private jwtHelper: JwtHelperService,
    private router: Router,
    private scriptService: ScriptService,
    private themeManager: ThemeManagerService,
    private accountAliases: AccountAliasesService,
    @Inject(PLATFORM_ID) private platformId: string,
    private configurationService: ConfigurationService,
    private ngZone: NgZone
  ) {}

  // Multiple requests shouldn't be made
  // App-bar is created for every navigation causing the issue
  subscriptionInfoRequestMade = false;

  /**
   *
   * @param accountId
   * @returns status of  past payments
   */
  getSubscriptionStatus(accountId: string) {
    if (
      projectEnv.get('projectName') !== 'admin-portal' ||
      environment.get('name') === 'on-premise'
    ) {
      return;
    }

    if (!this.subscriptionInfoRequestMade) {
      this.subscriptionInfoRequestMade = true;
      waitForSomeTime(10 * 1000).then(() => {
        this.apiService
          .get(`/v3/accounts/${accountId}/subscription-status`)
          .then((response) => {
            this.subscriptionInfoRequestMade = false;
            const { status, nextPaymentDate, subscriptionPastDueGracePeriod } =
              response.body;
            const subscriptionInfo: SubscriptionInfo = {
              status,
              subscriptionPastDueGracePeriod:
                subscriptionPastDueGracePeriod ?? 20,
            };

            if (status === 'past_due' && nextPaymentDate) {
              subscriptionInfo.paymentDueFrom = Math.abs(
                dateDifference(new Date(nextPaymentDate))
              );
            }
            this.dataCacheService.setCache(
              'subscription-info',
              subscriptionInfo
            );
            if (this.blockNavigationInPortal) {
              this.ngZone.run(() => {
                this.router.navigate(['/settings/billing-and-plans']);
              });
            }
          });
      });
    }
  }
  /**
   * Login the user. Used for
   * @param accountAlias Account ID / Tenant ID / Account Alias
   * @param email Email Address
   * @param password Password
   * @param twoFactorCode (Optional) Two factor code
   * @param twoFactorRecoveryCode ?
   */

  async login(body: LoginRequestBody): Promise<HttpResponse<any>> {
    this.email = body.email;
    this.password = body.password;

    // If user logged in using accountAlias, store accountAlias in service
    if ('accountAlias' in body) {
      this.accountAlias = body.accountAlias;
    }

    return this.apiService
      .post<any>('/v3/accounts/login', body)
      .then(async (response) => {
        const { accessToken, refreshToken } = response.body;
        const decodedToken = this.jwtHelper.decodeToken(accessToken);
        PersistentStore.setAccessToken(accessToken);
        PersistentStore.setRefreshToken(refreshToken);
        const region = PersistentStore.getRegion();
        /**region from token should be given precedence. As we are fetching account configuration, when region changes
         * setting the region only when accessToken  region differs from  locally stored one.
         */
        if (region && decodedToken?.region && region !== decodedToken.region) {
          this.setRegion(decodedToken.region);
        }
        this.loginState = 'email';
        return response;
      });
  }

  async signup(body: Signup): Promise<HttpResponse<any>> {
    return this.apiService
      .post<any>('/v3/accounts', body)
      .then(async (response) => {
        return response;
      });
  }

  verifyAccount(token: string, accountId: string, password: string) {
    return this.apiService
      .post<any>(`/v3/accounts/${accountId}/verify`, {
        token,
        password,
      })
      .then(async (response) => {
        await this.login({
          email: this.jwtHelper.decodeToken(token).email,
          password,
          accountId,
        });
        return response;
      });
  }

  /**
   * For SSO login, we must redirect the user to the link for their iDP. To do this, we ask them for their
   * account alias and accordingly redirect them. The rest of the process is between the iDP and the client.
   * @param accountAlias
   */
  async initSsoLogin(accountAlias: string) {
    const body = {
      accountAlias,
    };
    const returnUrl = `http${
      environment.get('name') === 'local' ? '' : 's'
    }://${window.location.host}`;
    await this.apiService.post('/v3/accounts/login/saml/verify', body);
    PersistentStore.set('accountAlias', accountAlias);
    this.loginState = 'sso';
    window.location.href = `${this.apiService.baseUrl}/v3/accounts/login/saml/${accountAlias}?returnUrl=${returnUrl}`;
  }

  /**
   * Makes a request to the Web API to request a password reset. On success, the user is sent an email with a reset link.
   */
  forgotPassword(accountAlias: string | undefined, email: string) {
    const body = { accountAlias, email };
    return this.apiService.post('/v3/accounts/reset-password-request', body);
  }

  /**
   * After clicking on a password reset link in their inbox, the user is prompted to set a new password.
   * This function handles communicating the newPassword with the Web API.
   */
  resetPassword(token: string, userId: string, newPassword: string) {
    const body = { newPassword, token };
    return this.apiService.post(`/v3/users/${userId}/reset-password`, body);
  }

  /**
   * Resets the returnUrl(?), clears the sessionExpiryTimeout and logs the user out of the application.
   */
  logout() {
    this.returnUrl = '/';
    // Shutdown Intercom
    if (isPlatformBrowser(this.platformId)) {
      shutdownIntercom();
    }
    resetPosthog();
    // Clear session expiration timeout
    // Initialize Intercom again with empty user.
    if (isPlatformBrowser(this.platformId)) {
      initializeIntercom(
        projectEnv.get('projectName'),
        environment.get('name'),
        getDashboardVersion(),
        this.scriptService,
        this.themeManager
      );
    }

    // Clear cache
    this.dataCacheService.clearCache();
    // Remove access token
    if (isPlatformBrowser(this.platformId)) {
      PersistentStore.removeAccessToken();
      PersistentStore.removeRefreshToken();
      this.router.navigate(['/auth/login']).then(() => {
        // Then force a full page reload to clear all states
        window.location.href = '/auth/login';
      });
    }
  }

  /**
   * Logs the user in using Google SSO.
   */
  async loginWithGoogleToken(
    accountAlias: string,
    credentialResponse: CredentialResponse
  ): Promise<void> {
    const email: string = this.jwtHelper.decodeToken(
      credentialResponse.credential
    ).email;
    const idToken: string = credentialResponse.credential;
    const body = {
      email,
      idToken,
      accountAlias,
    };
    await this.apiService
      .post('/v3/accounts/login-google', body)
      .then(async (response) => {
        const { accessToken, refreshToken } = response.body;
        const decodedToken = this.jwtHelper.decodeToken(accessToken);
        PersistentStore.setAccessToken(accessToken);
        PersistentStore.setRefreshToken(refreshToken);
        const region = PersistentStore.getRegion();
        /**region from token should be given precedence. As we are fetching account configuration, when region changes
         * setting the region only when accessToken  region differs from  locally stored one.
         */
        if (region && decodedToken?.region && region !== decodedToken.region) {
          this.setRegion(decodedToken.region);
        }
        this.loginState = 'gsi';
      });
  }

  /**
   * Sets the accountAlias for the account based on the domain name.
   */
  get domainAccountAlias() {
    let accountAlias;
    const ignoredDomains = ['localhost', '.dev.cryptlex.com'];
    const host = window.location.hostname.toLowerCase();
    if (ignoredDomains.includes(host) || host.endsWith('.cryptlex.com')) {
      accountAlias = '';
    }

    if (host.endsWith('.cryptlex.app')) {
      const subdomain = host.split('.')[0];
      if (subdomain.length >= 2) {
        accountAlias = subdomain;
      }
    } else {
      const subdomainParts = host.split('.');
      if (subdomainParts.length > 2) {
        accountAlias = host;
      }
    }
    return accountAlias;
  }

  /**
   * If there is an access token in the query params, set it in PersistentStore.
   */
  async handleQueryParamAccessToken(state: RouterStateSnapshot) {
    // IF accessToken in QueryParams, set the accessToken in localStorage
    if (
      isPlatformBrowser(this.platformId) &&
      state.root.queryParams['accessToken']
    ) {
      const token = state.root.queryParams['accessToken'];
      const decodedToken = this.jwtHelper.decodeToken(token);
      PersistentStore.setAccessToken(token);
      this.setRegion(decodedToken?.region);
      this.checkUserRole();
      // Remove AT from query params
      await this.router.navigate([], {
        queryParams: { accessToken: null },
        queryParamsHandling: 'merge',
      });
    }
  }

  async setRegion(value?: string) {
    let region = 'us';

    if (isPlatformBrowser(this.platformId)) {
      const storedRegion = PersistentStore.getRegion();
      if (value) {
        region = value;
      } else if (storedRegion) {
        region = storedRegion;
      }

      PersistentStore.setRegion(region);

      if (value) {
        await this.configurationService.getAndSetConfig();
      }

      let releaseServerBaseUrl = localStorage.getItem('releaseServerBaseUrl');
      /// TODO fix disjoint state. This logic should go through a single pathway.
      if (region === 'eu' && environment.get('name') === 'production') {
        releaseServerBaseUrl = localStorage.getItem('releaseServerBaseUrlEu');
      }

      if (releaseServerBaseUrl) {
        projectEnv.set('releaseServerBaseUrl', releaseServerBaseUrl);
      }
    }
  }

  /**
   * @returns False if the session is not authenticated to access the dashboard.
   */
  get isAuthenticated(): boolean {
    return this.isJwtValid;
  }
  /** Checks whether valid accessToken exists or not
   *  If not then refreshes the accesstoken if expired and refreshToken exists
   */
  async hasValidToken(): Promise<boolean> {
    let accessToken = null;
    let refreshToken = null;
    if (isPlatformBrowser(this.platformId)) {
      accessToken = PersistentStore.getAccessToken();
      refreshToken = PersistentStore.getRefreshToken();
    }

    // Check if access token is available
    if (!accessToken) {
      return false;
    }

    // Check if the access token is expired
    if (!this.jwtHelper.isTokenExpired(accessToken)) {
      return true; // Valid access token
    }

    // Handle session expiry if refresh token is available
    if (refreshToken) {
      await this.apiService.refreshTokens();

      return true; // Refresh token exists, consider session valid for now
    }

    return false; // No valid tokens
  }
  /** True if JWT exists, is valid, and has not expired */
  private get isJwtValid(): boolean {
    try {
      return (
        !!PersistentStore.getAccessToken() && !this.jwtHelper.isTokenExpired()
      );
    } catch (error) {
      return false;
    }
  }

  private get region(): string {
    try {
      return this.jwtHelper.decodeToken().region;
    } catch (error) {
      return '';
    }
  }

  /**
   * @returns Account ID or Tenant ID, if present in currently authenticated session's accessToken.
   */
  get accountId(): string {
    try {
      return this.jwtHelper.decodeToken().tenantid;
    } catch (error) {
      return '';
    }
  }

  /**
   * @returns Trial expiration date string, if present in currently authenticated session's accessToken.
   */
  get trialExpirationTime(): string | undefined {
    try {
      return this.jwtHelper.decodeToken()?.teat;
    } catch (error) {
      return;
    }
  }

  get planName() {
    const plan = this.dataCacheService
      .getCachedValues('plan')
      ?.find((plan: CtxPlanResponse) => {
        return (
          plan.id === this.dataCacheService.getCachedValues('profile')?.planId
        );
      });
    return plan?.name;
  }

  get trialExpirationDate() {
    if (this.trialExpirationTime && this.planName === 'trial') {
      const timeStamp = Number(this.trialExpirationTime);
      const date = new Date(timeStamp * 1000);
      return date;
    } else {
      return null;
    }
  }

  get isTrialExpired() {
    if (this.trialExpirationDate) {
      return this.trialExpirationDate.valueOf() <= Date.now();
    } else {
      return false;
    }
  }
  /**
   * True if currently logged in user is a super admin.
   */
  get isSuperAdmin(): boolean | undefined {
    try {
      return this.jwtRole === 'super-admin';
    } catch (error) {
      return;
    }
  }

  /**
   * Role property on the JWT
   */
  get jwtRole(): string | undefined {
    try {
      return this.jwtHelper.decodeToken().role;
    } catch (error) {
      return;
    }
  }
  /**
   * Role property on the JWT
   */
  get jwtRoleType(): string | undefined {
    try {
      return this.jwtHelper.decodeToken().role_type;
    } catch (error) {
      return;
    }
  }

  /**
   * @returns Account Alias for the account based on the domain name or user email.
   */
  async getAccountAlias(email?: string) {
    let accountAlias = this.accountAlias;
    const host = window.location.hostname.toLowerCase();

    if (host.endsWith('.cryptlex.com')) {
      // cryptlex official domains
      if (projectEnv.get('projectName') === 'admin-portal' && email) {
        // admin portal
        const userAccountAlias = await this.accountAliases.getUserAccountAlias(
          email
        );
        accountAlias = userAccountAlias;
      } else {
        // customer and reseller portals
        const subdomain = host.split('.')[0];
        accountAlias = subdomain;
      }
    } else {
      // TODO
      // custom domains of customers for all portals
      accountAlias = host;
    }

    return accountAlias;
  }
  /**
   * @returns hostname
   */
  getHostname() {
    const host = window.location.hostname.toLowerCase();
    return host;
  }
  /**
   * Gets the allowed roles based on the project name.
   * @returns An array of allowed roles.
   */
  get allowedRoleTypes(): string[] {
    const projectName = projectEnv.get('projectName');
    if (projectName === 'customer-portal') {
      return ['user', 'organization'];
    } else if (projectName === 'reseller-portal') {
      return ['reseller'];
    } else if (projectName === 'admin-portal') {
      return ['admin'];
    } else {
      return [];
    }
  }
  /**
   * Redirects the user based on their role type.
   * If the role type is allowed, it navigates to the home page.
   * If the role type is not allowed, it opens the IncorrectRoleError Component.
   */
  async checkUserRole() {
    const roleType = this.jwtRoleType;
    if (roleType && this.allowedRoleTypes.includes(roleType)) {
      await this.router.navigate(['/']);
    } else {
      this.dialog.open(IncorrectRoleErrorComponent, {
        disableClose: true,
        data: {
          logout: this.logout.bind(this),
        },
      });
      return Promise.reject(new Error('User not allowed'));
    }
  }

  get blockNavigationInPortal() {
    const subscriptionInfo: SubscriptionInfo =
      this.dataCacheService.getCachedValues('subscription-info');
    const account = this.dataCacheService.getCachedValues('account');
    return (
      (subscriptionInfo?.status === 'past_due' &&
        subscriptionInfo.paymentDueFrom &&
        subscriptionInfo.paymentDueFrom >
          subscriptionInfo.subscriptionPastDueGracePeriod) || // past due and paymnet not paid for a time
      (subscriptionInfo?.status === 'no_subscription' &&
        account?.plan.name !== 'free' &&
        account?.plan.name !== 'trial')
    ); // no subscription on a paid plan
  }
}

/** Guard for '/auth' routes */
export const canAccessAuthRoutes: CanActivateFn = async () => {
  const authn = inject(AuthnService);
  const router = inject(Router);
  // Navigate to dashboard if user is authenticated
  const validToken = await authn.hasValidToken();
  if (validToken) {
    await router.navigate(['/']);
  }
  // Always returns true, users can always access the auth routes.
  return true;
};

/** Guard for '/' routes */
export const canAccessDashboard: CanActivateFn = async (_route, state) => {
  const authn = inject(AuthnService);
  const router = inject(Router);

  //on refresh if one is logged in we don't select region hence ngsw used wrong api
  await authn.setRegion();
  await authn.handleQueryParamAccessToken(state);
  const validToken = await authn.hasValidToken();

  if (validToken) {
    return true;
  } else {
    authn.logout();
    await router.navigate(['/auth']);
    return false;
  }
};
