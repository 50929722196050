import { Injectable } from '@angular/core';
import {
  ALL_COUNTRIES,
  Filter,
  ResourceName,
  getLabelForComparisonOperator,
  getPropertyForId,
  getResourceDisplayName,
  projectEnv,
} from 'utils';

const CACHED_RESOURCE_NAMES = [
  'account',
  'address',
  'role',
  'tag',
  'product-version',
  'maintenance-policy',
  'role-claim',
  'product',
  'license-template',
  'trial-policy',
  'automated-email',
  'automated-email-event',
  'profile',
  'webhook-trigger',
  'webhook',
  'release-platform',
  'release-channel',
  'plan',
  'card',
  'saml-configuration',
  'organization',
  'sending-domain',
  'user-group',
  'admin-role',
  'oidc-configuration',
  'organization-claim',
  'reseller-claim',
  'subscription-info',
] as const;
type _CN = (typeof CACHED_RESOURCE_NAMES)[number];
export type CachedResourceName = Extract<ResourceName, _CN>;

/** Type for cacheObject */
type DataCache = Partial<Record<CachedResourceName, any>>;

@Injectable({
  providedIn: 'root',
})
export class DataCacheService {
  private dataCache: DataCache = {};

  /**
   *
   * @param resourceName
   * @param value
   */
  setCache(resourceName: ResourceName, value: any): void {
    /**
     * https://github.com/microsoft/TypeScript/issues/26255
     * https://github.com/microsoft/TypeScript/issues/14520
     * https://github.com/microsoft/TypeScript/issues/14520#issuecomment-904915610
     *
     */

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: This is a workaround till TS supports subtyping
    if (CACHED_RESOURCE_NAMES.includes(resourceName)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: This is a workaround till TS supports subtyping
      this.dataCache[resourceName] = value;
    }
  }
  /**
   *
   * @param resourceName
   * @returns
   */
  getCachedValues(resourceName: CachedResourceName): any[] | any | undefined {
    return this.dataCache[resourceName];
  }

  /**
   * Set cache value to undefined
   */
  invalidateCache(resourceName: ResourceName): void {
    this.setCache(resourceName, undefined);
  }

  /**
   * WARNING: This will delete all cached items. Use only on logout.
   */
  clearCache(): void {
    this.dataCache = {};
  }
}

/**
 * Creates title from the generatedFilters query
 */
export function getLabelForFilter(
  filter: Filter,
  dataCacheService: DataCacheService
) {
  const filterValue = (property: string, value: string) => {
    // If displayValue prop has been set, use that for the value
    if (filter.displayValue) {
      return filter.displayValue;
    }

    switch (property) {
      case 'productId':
        return getPropertyForId(
          dataCacheService.getCachedValues('product'),
          value,
          'name'
        );
      case 'productVersionId':
        return getPropertyForId(
          dataCacheService.getCachedValues('product-version'),
          value,
          'name'
        );
      case 'webhookId':
        return getPropertyForId(
          dataCacheService.getCachedValues('webhook'),
          value,
          'name'
        );
      case 'automatedEmailId':
        return getPropertyForId(
          dataCacheService.getCachedValues('automated-email'),
          value,
          'name'
        );
      case 'location.countryCode': {
        return ALL_COUNTRIES[value];
      }
      case filter.property.match(/.*[a-zA-Z]At$/) ? filter.property : '':
        return new Date(value).toLocaleDateString();
      default:
        return value;
    }
  };

  let result = '';
  if (Array.isArray(filter.value)) {
    result = filter.value
      .map((v) => {
        return filterValue(filter.property, v);
      })
      .join(' or ');
  } else {
    result = filterValue(filter.property, filter.value);
  }

  return `${getResourceDisplayName(
    filter.property,
    projectEnv.get('projectName')
  )} ${getLabelForComparisonOperator(filter.operator)} ${result}`;
}
