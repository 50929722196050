import { ResourceName } from './resource-names';

export const RESOURCE_DEFINITIONS: Record<ResourceName, string> = {
  'account': 'Your organization account.',
  'product': 'Products are the software products you want to license',
  'license':
    'Licenses represent a purchase of your software. These can be linked to customers, and the license key is required to use the product.',
  'access-token': 'Access Tokens are used to authenticate your API requests.',
  'activation':
    'Activations, also known as devices/machines/seats are the devices consuming licenses.',
  'activation-log':
    'Activation Log is a log entry of activation/deactivation of a particular license.',
  'trial':
    'Trial/Trial Activation is a device that has activated a trial of your product.',
  'audit-log': 'Audit logs contain all the changes made to your account.',
  'automated-email':
    'Automated Email allow you to send marketing emails based on events on the linked product.',
  'automated-email-event': 'Events on which automated email can be send.',
  'automated-email-event-log':
    'Automated email event log is the log of all the automated email events for your product.',
  'card': 'The payment card for your account.',
  'feature-flag':
    'Feature flags define features that make up tiers for your products.',
  'invoice': '',
  'license-template':
    'License templates are a blueprint for the licenses you create for your customers and prevent repetition when creating licenses.',
  'maintenance-policy':
    'Maintenance policies represent support contracts and can be linked to licenses.',
  'plan': '',
  'product-version':
    'Product Versions are sets of Feature Flags that define the tiers of your products.',
  'release-channel': 'Release channel is the release channel for your product.',
  'release-file': 'Release files are files within your created releases.',
  'release-platform':
    'Release Platforms differentiate the target platform for your release. Common platforms include "Windows", "macOS", and "Linux".',
  'release':
    'Releases help you to manage different versions of your app, and secure distribute it to licensed users.',
  'report': 'Analytics data for your account',
  'role-claim': '',
  'role': 'Roles define permissions for your team.',
  'saml-configuration': '',
  'segment': 'Sets of filters that can be saved to filter resources.',
  'trial-policy':
    'Trial policies are templates for creating trials for your products.',
  'webhook-event-log':
    'Webhook Event Logs are logs of events that have occured on webhooks.',
  'webhook-trigger': '',
  'webhook':
    'Webhooks are HTTP callbacks which are triggered by specific events.',
  'organization': '',
  'profile': '',
  'setting': '',
  'tag':
    'Tags allow you to manage your licenses and customers on the dashboard.',
  'team-member': 'Team members can access the account based on their roles.',
  'user':
    'A user refers to your customer whom you want to license your product.',
  'sending-domain':
    'Allows Cryptlex to send emails on your behalf using your From Email address',
  'admin-role': 'Roles that have type admin',
  'user-group': 'Groups of users that you can assign licenses to.',
  'reseller':
    'Resellers allow you to delegate user management to third parties or partners',
  'oidc-configuration': '',
  'organization-claim': '',
  'reseller-claim': '',
  'address': '',
  'subscription-info':
    'Status of the  subscription and the number of days from which payment is due if applicable',
};
