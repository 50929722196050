import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ConfigurationService, updateIntercom } from 'ng-ui';
import { AccountService } from 'admin-portal/pages/account/account.service';
import { SamlConfigurationService } from 'admin-portal/pages/account/saml-configuration.service';
import { AutomatedEmailService } from 'admin-portal/pages/automated-emails/automated-email.service';
import { PaymentCardService } from 'admin-portal/pages/billing-and-plans/card.service';
import { LicenseTemplateService } from 'admin-portal/pages/license-templates/license-template.service';
import { PlanService } from 'admin-portal/pages/billing-and-plans/plan.service';
import { MaintenancePolicyService } from 'admin-portal/pages/maintenance-policies/maintenance-policy.service';
import { ProductVersionService } from 'admin-portal/pages/product-versions/product-version.service';
import { ProductService } from 'admin-portal/pages/products/product.service';
import { ProfileService } from 'ng-ui';
import { ReleaseChannelService } from 'admin-portal/pages/release-channels/release-channel.service';
import { ReleasePlatformService } from 'admin-portal/pages/release-platforms/release-platform.service';
import { RoleClaimService } from 'admin-portal/pages/roles/role-claim.service';
import { RoleService } from 'admin-portal/pages/roles/role.service';
import { TagService } from 'admin-portal/pages/tags/tag.service';
import { TrialPolicyService } from 'admin-portal/pages/trial-policies/trial-policy.service';
import { WebhookTriggerService } from 'admin-portal/pages/webhooks/webhook-trigger.service';
import { AuthnService, DataCacheService, resolveEntitiesToCache } from 'ng-ui';
import { identifyPosthogGroup, identifyPosthogUser } from 'utils';
import { WebhookService } from 'admin-portal/pages/webhooks/webhook.service';
import { AdminRoleService } from 'admin-portal/pages/team/admin-role.service';
import { OidcConfigurationService } from 'admin-portal/pages/account/oidc-configuration.service';
import { OrganizationClaimService } from 'admin-portal/pages/roles/organization-claim.service';
import { ResellerClaimService } from 'admin-portal/pages/roles/reseller-claim.service';
import { AutomatedEmailEventService } from 'admin-portal/pages/automated-emails/automated-email-event.service';
import { AddressService } from 'admin-portal/pages/billing-and-plans/address.service';

export const resolveAutomatedEmails: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    AutomatedEmailService,
    'automated-email'
  );
};

export const resolveAutomatedEmailEvents: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    AutomatedEmailEventService,
    'automated-email-event'
  );
};
export const resolveReleasePlatforms: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    ReleasePlatformService,
    'release-platform'
  );
};

export const resolveReleaseChannels: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    ReleaseChannelService,
    'release-channel'
  );
};

export const resolveProfile: ResolveFn<any> = (route, state) => {
  const _authnService = inject(AuthnService);
  return resolveEntitiesToCache(route, state, ProfileService, 'profile').then(
    (profile) => {
      if (!profile) {
        return;
      }
      updateIntercom({
        name: profile.name,
        email: profile.email,
        user_id: profile.id,
        created_at: profile.createdAt,
        user_role: profile.role,
        company: {
          id: _authnService.accountId,
        },
      });

      identifyPosthogUser({ id: profile.id });
    }
  );
};

export const resolveProducts: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, ProductService, 'product');
};

export const resolveProductVersions: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    ProductVersionService,
    'product-version'
  );
};

export const resolveRoles: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, RoleService, 'role');
};

export const resolveTags: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, TagService, 'tag');
};

export const resolveTrialPolicies: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    TrialPolicyService,
    'trial-policy'
  );
};
export const resolveAdminRoles: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, AdminRoleService, 'admin-role');
};

export const resolveMaintenancePolicies: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    MaintenancePolicyService,
    'maintenance-policy'
  );
};

export const resolveLicenseTemplates: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    LicenseTemplateService,
    'license-template'
  );
};

export const resolveWebhookTriggers: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    WebhookTriggerService,
    'webhook-trigger'
  );
};

export const resolveWebhook: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, WebhookService, 'webhook');
};

export const resolveRoleClaims: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, RoleClaimService, 'role-claim');
};

export const resolveOrganziationClaims: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    OrganizationClaimService,
    'organization-claim'
  );
};

export const resolveResellerClaims: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    ResellerClaimService,
    'reseller-claim'
  );
};

export const resolvePlans: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, PlanService, 'plan');
};
export const resolveAddress: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, AddressService, 'address');
};

export const resolveCards: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(route, state, PaymentCardService, 'card');
};

export const resolveSamlConfiguration: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    SamlConfigurationService,
    'saml-configuration'
  );
};
export const resolveOidcConfiguration: ResolveFn<any> = (route, state) => {
  return resolveEntitiesToCache(
    route,
    state,
    OidcConfigurationService,
    'oidc-configuration'
  );
};

export const resolveAccount: ResolveFn<any> = () => {
  const _dataCache = inject(DataCacheService);
  const _authnService = inject(AuthnService);
  const _accountService = inject(AccountService);
  const _configService = inject(ConfigurationService);
  if (_dataCache.getCachedValues('account')) {
    return _dataCache.getCachedValues('account');
  } else if (_authnService.accountId) {
    return _accountService
      .read(_authnService.accountId)
      .then(async (response) => {
        const account = response.body;
        if (account) {
          _dataCache.setCache('account', account);
          updateIntercom({
            company: {
              id: account.id,
              name: account.company,
              created_at: new Date(account.createdAt).getTime() / 1000,
              website: account.website,
              plan: account.plan.name,
            },
          });

          identifyPosthogGroup({
            id: account.id,
            name: account.company,
            plan: account.plan.name,
          });

          /** This will make sure everything is updated like webPortalTheme, favicon,icon,company name  */
          if (account) {
            _configService.setConfig(account);
          }
          return account;
        } else {
          return null;
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  } else {
    // eslint-disable-next-line no-console
    console.error('No accountId found.');
    return null;
  }
};
