export type FilterableProperties = {
  [filterProperty: string]: FilterablePropertyType;
};

export type Filter = {
  /** Property which is used in QueryParam in Web API request */
  property: string;
  /** Operator determines the type of query */
  operator: FilterComparisonOperator;
  /** Query value */
  value: string | string[];

  /** Value to display, this property is set in the Frontend to prettify the Filter labels */
  displayValue?: string;
};
/**
 * Type with the 'object' prefix have support for object based operators whereas
 * the other types do not. This is an important distinction to make when sending the Web API
 * a request.
 *
 * As a convention, we add the object suffix to any FilterablePropertyType that has object operators
 */
export const FILTERABLE_PROPERTY_TYPES = [
  'objectString',
  'string',
  'number',
  'objectNumber',
  'boolean',
  'objectDate',
  'licenseType',
  'product',
  'product-version',
  'user',
  'webhook',
  'automated-email',
  'organization',
  'country',
  'tag',
  'resource',
  'resourceId',
  'reseller',
  'role',
  'note',
  'os',
  'action',
] as const;
export type FilterablePropertyType = (typeof FILTERABLE_PROPERTY_TYPES)[number];

export type OperatorsForFilterType = Record<
  FilterablePropertyType,
  FilterComparisonOperator[]
>;

export const FILTER_COMPARISON_OPERATORS = [
  'eq',
  'ne',
  'cn',
  'nc',
  'sw',
  'ew',
  'in',
  'nin',
  'gt',
  'gte',
  'lt',
  'lte',
] as const;
export type FilterComparisonOperator =
  (typeof FILTER_COMPARISON_OPERATORS)[number];

export const COMPARISON_OPERATOR_LABELS: Record<
  FilterComparisonOperator,
  string
> = {
  eq: 'equal to',
  ne: 'not equal to',
  cn: 'contains',
  nc: 'does not contain',
  sw: 'starts with',
  ew: 'ends with',
  in: 'includes',
  nin: 'does not include',
  gt: 'greater than',
  gte: 'greater than or equal to',
  lt: 'less than',
  lte: 'less than or equal to',
};

export const OPERATORS_FOR_FILTER_TYPE: OperatorsForFilterType = {
  'objectString': ['eq', 'ne', 'cn', 'nc', 'sw', 'ew', 'in', 'nin'],
  'objectDate': ['gt', 'lt', 'eq'],
  'objectNumber': ['eq', 'ne', 'gt', 'gte', 'lt', 'lte'],
  'tag': ['in', 'nin'],
  'string': ['eq'],
  'number': ['eq'],
  'boolean': ['eq'],
  'licenseType': ['in', 'nin'],
  'product': ['in', 'nin'],
  'product-version': ['in', 'nin'],
  'user': ['eq'],
  'reseller': ['eq'],
  'webhook': ['in', 'nin'],
  'automated-email': ['in', 'nin'],
  'organization': ['eq'],
  'country': ['in', 'nin'],
  'resource': ['in', 'nin'],
  'resourceId': ['eq', 'ne', 'in', 'nin'],
  'role': ['in', 'nin'],
  'note': ['eq', 'ne', 'cn', 'nc', 'sw', 'ew'],
  'os': ['in', 'nin'],
  'action': ['in', 'nin'],
};
