import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { CtxForm } from 'ng-ui';
import {
  convertCamelCaseToTitleCase,
  CtxProjectName,
  CtxValidators,
  ResourceUpdateFormData,
} from 'utils';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CtxStepperNextDirective } from 'ng-ui';
import { CtxButtonComponent } from 'ng-ui';
import { CtxStepComponent } from 'ng-ui';
import { CtxStepperComponent } from 'ng-ui';
import { CtxAlertComponent } from 'ng-ui';

@Component({
  selector: 'ctx-custom-domains-form',
  templateUrl: './custom-domains-form.component.html',
  standalone: true,
  imports: [
    MatDialogTitle,
    CtxAlertComponent,
    MatDialogContent,
    CtxStepperComponent,
    CtxStepComponent,
    MatDialogActions,
    CtxButtonComponent,
    MatDialogClose,
    CtxStepperNextDirective,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class CustomDomainsFormComponent extends CtxForm implements OnInit {
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CustomDomainsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ResourceUpdateFormData
  ) {
    super();
  }
  action = this.setActionLabel(this.dialogData.resource.portal);

  setActionLabel(portal: CtxProjectName) {
    switch (portal) {
      case 'customer-portal':
        return this.getInitialControlValue(
          'customerPortalDomain',
          this.dialogData
        )
          ? 'Update'
          : 'Add';
      case 'reseller-portal':
        return this.getInitialControlValue(
          'resellerPortalDomain',
          this.dialogData
        )
          ? 'Update'
          : 'Add';
      case 'admin-portal':
        return this.getInitialControlValue('adminPortalDomain', this.dialogData)
          ? 'Update'
          : 'Add';
      default:
        return 'Add';
    }
  }
  convertCamelCaseToTitleCase = convertCamelCaseToTitleCase;
  ngOnInit(): void {
    this.formGroup = this.fb.group(
      {
        customerPortalDomain: new FormControl(
          {
            value: this.getInitialControlValue(
              'customerPortalDomain',
              this.dialogData
            ),
            disabled: this.dialogData.resource.portal !== 'customer-portal',
          },
          [Validators.maxLength(255)]
        ),
        resellerPortalDomain: new FormControl(
          {
            value: this.getInitialControlValue(
              'resellerPortalDomain',
              this.dialogData
            ),
            disabled: this.dialogData.resource.portal !== 'reseller-portal',
          },
          [Validators.maxLength(255)]
        ),
        adminPortalDomain: new FormControl(
          {
            value: this.getInitialControlValue(
              'adminPortalDomain',
              this.dialogData
            ),
            disabled: this.dialogData.resource.portal !== 'admin-portal',
          },
          [Validators.maxLength(255)]
        ),
      },
      { validators: CtxValidators.duplicateCustomDomains }
    );
  }
}
